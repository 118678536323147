import { removeEmpty } from '../helpers/helper';

import { loadUtag, utag } from './tealium_helper';
import WrappedProvider from './wrapped_provider';

// Underscore session, user, and visitor ids
const formatIds = ({ sessionId, userId, visitorId, ...obj }) => ({
  ...obj,
  ...removeEmpty({ session_id: sessionId, user_id: userId, visitor_id: visitorId }),
});

function tealiumUtag(name, props, utagMethod = 'link') {
  utag(utagMethod, [
    formatIds({
      tealium_event: name,
      ...props,
    }),
  ]);
}

class TealiumProvider extends WrappedProvider {
  load() {
    loadUtag(this.config.accountProfileEnv);
  }

  // eslint-disable-next-line class-methods-use-this
  group({ data, ...props }) {
    tealiumUtag('Group', { ...data, ...props });
  }

  // eslint-disable-next-line class-methods-use-this
  identify({ data, ...props }) {
    tealiumUtag('Identify', { ...data, ...props });
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  page({ data, pageName = '', ...props }) {
    tealiumUtag(
      'Viewed Page',
      {
        page_name: pageName,
        ...data,
        ...props,
      },
      'view',
    );
  }

  // eslint-disable-next-line class-methods-use-this
  track({ data, eventCategory, eventName, ...props }) {
    tealiumUtag(`${eventCategory}${eventName}`, {
      event_category: eventCategory,
      event_name: eventName,
      ...data,
      ...props,
    });
  }
}

TealiumProvider.configKey = 'tealium';
TealiumProvider.isApprovedProvider = false;
TealiumProvider.providerName = 'Tealium';

export default TealiumProvider;
