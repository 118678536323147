export default class ProviderWrapper {
  constructor({ log, provider }) {
    this.loaded = false;
    this.log = log;
    this.provider = provider;
  }

  isApprovedProvider() {
    return this.provider.constructor.isApprovedProvider;
  }

  load() {
    if (!this.loaded) {
      this.delegate('load');
      this.loaded = true;
    }
  }

  identify(...args) {
    this.delegate('identify', args);
  }

  group(...args) {
    this.delegate('group', args);
  }

  page(...args) {
    this.delegate('page', args);
  }

  track(...args) {
    this.delegate('track', args);
  }

  delegate(method, args = []) {
    this.log(`${method} ${this.providerName()}`, ...args);
    this.provider[method](...args);
  }

  providerName() {
    return this.provider.constructor.providerName;
  }
}
