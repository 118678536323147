import { createIsbotFromList, list } from 'isbot';
import store from 'store2';
import uuid4 from 'uuid4';
import packageConfig from '../package.json';
import {
  GROUP_ID_TYPE_MAP,
  DEFAULT_OMITTED_TRACKING_PROVIDERS,
  SCROLL_PERCENTAGES,
  FIRST_SESSION_COOKIE_NAME,
  BOT_AGENTS,
  ONETRUST_COOKIE_NAME,
} from './constants';
import * as CookiesUtils from './cookies_utils';
import Experimentation from './experiment_id';
import AdFraudDetection from './fraud_id';
import * as Helper from './helpers/helper';
import * as InspectletHelper from './helpers/inspectlet_helper';
import {
  globalProperties,
  eventProperties,
  clickSchemaProperties,
  scrollProperties,
} from './helpers/properties_helper';
import * as SnowplowHelper from './helpers/snowplow_helper';
import { getClientUserAgent } from './helpers/window_helper';
import { loadProviders } from './load_providers';
import PageView from './page_view';
import { dataLayerPush as tealiumDataLayerPush } from './providers/tealium_helper';
import Session from './session';
import trackError from './track_error';
import UserProfileService from './user_profile_service_id';
import UserTargeting from './user_targeting_id';
import Visitor from './visitor';

const ENABLE_ANALYTICS_LOGS = 'enable_analytics_logs';
const sessionStore = () => store.session;

const isbot = createIsbotFromList(list.concat(BOT_AGENTS));

class GustoAnalytics {
  constructor(config) {
    this.config = config;
    this._version = packageConfig.version;
    this.currentPageView = null;
    this.activeProviders = [];
    this._session = Session;
    window.GustoAnalytics = window.GustoAnalytics || [];
  }

  // For testing purposes
  get ga() {
    const stub = this.config.stubAnalytics;

    return (stub && stub.ga ? stub : window).ga;
  }

  // For testing purposes
  get analytics() {
    const stub = this.config.stubAnalytics;

    return (stub && stub.analytics ? stub : window).analytics;
  }

  get displayLog() {
    const defaultVal = this.config.environment === 'development';
    return sessionStore().get(ENABLE_ANALYTICS_LOGS, defaultVal);
  }

  set displayLog(val) {
    sessionStore()(ENABLE_ANALYTICS_LOGS, val);
  }

  log(...args) {
    if (this.displayLog === true) {
      window.console.log('[ANALYTICS]', ...args);
    }
  }

  loadAnalytics() {
    this.log({
      driftEnabled: !!this.config.driftApiKey,
      fullstoryEnabled: this.config.fullstoryEnabled,
      snowplowEnabled: this.config.snowplowEnabled,
      inspectletEnabled: this.config.inspectletEnabled,
      autoPageEvent: this.autoPageEvent(),
      serverSideEligibleProviders: this.config.serverSideEligibleProviders,
      blockedEndpointsFromCookieSet: this.config.blockedEndpointsFromCookieSet,
    });

    if (this.config.snowplowEnabled) {
      SnowplowHelper.init(
        this.config.snowplowTrackerName,
        this.config.snowplowTrackingUrl,
        this.config.snowplowAppId,
      );
    }

    if (this.config.inspectletEnabled) {
      InspectletHelper.init(this.config.inspectletWid);
    }

    this.activeProviders.push(
      ...loadProviders({
        configs: this.config.providers,
        log: (...args) => this.log(...args),
      }),
    );
  }

  analyticsReady(callback) {
    if (typeof callback !== 'function') {
      return;
    }

    // Set visitor ID if it doesn't exist
    AdFraudDetection.getAdFraudDetectionId();
    Visitor.getVisitorId();
    Experimentation.getExperimentationId();
    UserProfileService.getUserProfileServiceId();
    UserTargeting.getUserTargetingId();

    // Set session_start_needed cookie is session ID doesn't exist
    // Set session ID if it doesn't exist
    Session.setSessionStartNeeded();
    Session.getSessionId();

    callback();
  }

  // Associate users and their actions
  // prop (hash parameters):
  //   userId = optional
  //   data = optional
  identify(prop = {}) {
    let data = Helper.deepCopyMerge({}, prop.data);
    // Try to get user ID from config if not passed in
    let userId = prop.userId || this.config.userId;
    let impersonatedUserId;
    const pandaAdminId = this.config.pandaAdminId;

    data.impersonated_user_id = null;
    if (pandaAdminId && userId && String(pandaAdminId) !== String(userId)) {
      impersonatedUserId = userId;
      data.impersonated_user_id = impersonatedUserId;
      userId = pandaAdminId;
    }

    const userRoleId = this.getUserRoleId();
    const sessionId = Session.getSessionId();
    AdFraudDetection.getAdFraudDetectionId();

    const oldVisitorId = Visitor.getVisitorId();
    const oldVisitorIdOriginHash = Visitor.getVisitorIdOriginHash();
    const newVisitorId = Visitor.checkResetVisitorId(userId);
    const newVisitorIdOriginHash = Visitor.getVisitorIdOriginHash();
    const visitorId = newVisitorId || oldVisitorId;
    UserProfileService.checkResetUserProfileServiceId(userId);

    if (this.config.debugIdentify) {
      this.track({
        eventCategory: 'AnalyticsDebug',
        eventName: 'Identify',
        data: {
          identifyProperties: {
            userId,
            oldVisitorId,
            oldVisitorIdOriginHash,
            newVisitorId,
            newVisitorIdOriginHash,
            impersonatedUserId,
            visitorChanged: !!newVisitorId,
            source: 'js',
          },
          analyticsConfig: {
            userId: this.config.userId,
          },
        },
      });
    }

    this.log('identify args', arguments);

    // Add version number to data
    data.gusto_analytics_version = this._version;

    // Add panda admin ID to data if available
    if (pandaAdminId) {
      data.panda_admin_id = pandaAdminId;
    }

    // Add user role ID to data if available and not part of data already
    if (!data.user_role_id && userRoleId) {
      data.user_role_id = userRoleId;
    }

    // Add isBot to property to detect bot status through useragent
    data.is_bot = isbot(getClientUserAgent());

    // Add tracking cookies to data, if data and tracking cookies have same key, the value in data take precedence
    data = Helper.deepCopyMerge(CookiesUtils.getTrackingCookies(), data);

    // Make a copy for in case data is modified
    // Add session ID to snowplow event data
    const dataCopy = Helper.deepCopyMerge({}, data, { session_id: sessionId });

    // If the identify call is intended for one of [AmplitudeProvider, FullstoryProvider]
    // We will pass the data directly to target provider and skip other providers for data security reason.
    if (prop.targetProvider) {
      const targetProvider = this.activeProviders.find(
        provider => provider.providerName() === prop.targetProvider,
      );
      if (targetProvider) {
        targetProvider.identify({ userId, visitorId, sessionId, data });
      }
      return;
    }

    if (this.config.snowplowEnabled) {
      const eventCategory = SnowplowHelper.eventCategories().VISITOR_EVENT;
      const eventName = 'Identify';
      const schema = SnowplowHelper.generateSchema('', 'identify', true);
      const serverSideEligibleProviders = this.config.serverSideEligibleProviders;
      const eventData = SnowplowHelper.prepareData(
        userId,
        visitorId,
        dataCopy,
        serverSideEligibleProviders,
      );

      this.log('identify snowplow', eventData);

      SnowplowHelper.snowplowEvent(eventCategory, eventName, schema, eventData);
    }

    if (this.config.inspectletEnabled) {
      const identity = Helper.deepCopyMerge({}, data, { visitorId, userId });

      this.log('identify inspectlet', identity);

      InspectletHelper.tagSession(identity);
    }

    data = Helper.deepCopyMerge(this.getSinglePurposeIdentifiers(), dataCopy);
    this.activeProviders.forEach(provider => {
      if (!SnowplowHelper.isBotUserAgent()) {
        provider.identify({ userId, visitorId, sessionId, data });
      }
    });
  }

  // Record page views
  // prop (hash parameters):
  //   userId = optional
  //   name = optional
  //   data = optional
  page(prop = {}) {
    let data = Helper.deepCopyMerge({}, prop.data);
    // Try to get user ID from config if not passed in
    let userId = prop.userId || this.config.userId;

    const name = prop.name;
    AdFraudDetection.getAdFraudDetectionId();
    const visitorId = Visitor.getVisitorId();
    const pandaAdminId = this.config.pandaAdminId;
    const userRoleId = this.getUserRoleId();
    const sessionId = Session.getSessionId();

    this.currentPageView = new PageView();
    data.page_view_id = this.currentPageView.pageViewId();

    data.impersonated_user_id = null;
    if (pandaAdminId && userId && String(pandaAdminId) !== String(userId)) {
      data.impersonated_user_id = userId;
      userId = pandaAdminId;
    }

    this.log('page args', arguments);

    // Add version number to data
    data.gusto_analytics_version = this._version;

    // Add panda admin ID to data if available
    if (pandaAdminId) {
      data.panda_admin_id = pandaAdminId;
    }

    // Add user role ID to data if available and not part of data already
    if (!data.user_role_id && userRoleId) {
      data.user_role_id = userRoleId;
    }

    // Add isBot to property to detect bot status through useragent
    data.is_bot = isbot(getClientUserAgent());

    // Add referrer, path, url, and title to data
    // Add tracking params (utm_*, vt_*, gclid) to data
    // Add tracking cookies to data
    // Values in developer specified data take precedence
    data = Helper.deepCopyMerge(
      Helper.getPageInfo(),
      Helper.getTrackingParams(),
      CookiesUtils.getTrackingCookies(),
      data,
    );

    // Make a copy in case data is modified
    // Add session ID to snowplow event data
    let dataCopy = Helper.deepCopyMerge({}, data, { session_id: sessionId });
    dataCopy = {
      ...dataCopy,
      url_query_params: Helper.getTrackingParams(),
      non_attribution_cookies: CookiesUtils.getTrackingCookies(),
    };

    if (this.config.snowplowEnabled) {
      const schema = SnowplowHelper.generateSchema('', 'pageView', true);
      const serverSideEligibleProviders = this.config.serverSideEligibleProviders;
      const eventData = SnowplowHelper.prepareData(
        userId,
        visitorId,
        dataCopy,
        serverSideEligibleProviders,
      );

      this.log('page snowplow', name, schema, eventData);

      SnowplowHelper.pageView(name, schema, eventData);
    }

    data = Helper.deepCopyMerge(this.getSinglePurposeIdentifiers(), dataCopy);

    this.activeProviders.forEach(provider => {
      if (!SnowplowHelper.isBotUserAgent()) {
        provider.page({ pageName: name, userId, visitorId, sessionId, data });
      }
    });
  }

  // Associates identified user with a company/company lead
  // prop (hash parameters):
  //   groupId = 'L...' for company lead, 'C...' for company, 'A...' for accounting firm
  //   userId = optional
  //   data = optional
  group(prop) {
    let groupType;
    let data = Helper.deepCopyMerge({}, prop.data);
    // Try to get user ID from config if not passed in
    let userId = prop.userId || this.config.userId;
    const groupId = prop.groupId;
    AdFraudDetection.getAdFraudDetectionId();
    const visitorId = Visitor.getVisitorId();
    const pandaAdminId = this.config.pandaAdminId;
    const userRoleId = this.getUserRoleId();
    const sessionId = Session.getSessionId();

    data.impersonated_user_id = null;
    if (pandaAdminId && userId && String(pandaAdminId) !== String(userId)) {
      data.impersonated_user_id = userId;
      userId = pandaAdminId;
    }

    if (!groupId) {
      this.log('Must provide groupId');
      return;
    }

    this.log('group args', arguments);

    // Add version number to data
    data.gusto_analytics_version = this._version;

    // Add panda admin ID to data if available
    if (pandaAdminId) {
      data.panda_admin_id = pandaAdminId;
    }

    // Add user role ID to data if available and not part of data already
    if (!data.user_role_id && userRoleId) {
      data.user_role_id = userRoleId;
    }

    // Add isBot to property to detect bot status through useragent
    data.is_bot = isbot(getClientUserAgent());

    // Add referrer, path, url, and title to data
    // Add tracking cookies to data
    // Values in developer specified data take precedence
    data = Helper.deepCopyMerge(Helper.getPageInfo(), CookiesUtils.getTrackingCookies(), data);

    // Make a copy in case data is modified
    // Add session ID for snowplow event data
    const dataCopy = Helper.deepCopyMerge({}, data, { session_id: sessionId });

    if (this.config.snowplowEnabled) {
      const eventCategory = SnowplowHelper.eventCategories().VISITOR_EVENT;
      const eventName = 'Group';
      const newGroupId = groupId.substring(1);

      groupType = GROUP_ID_TYPE_MAP[groupId[0]];

      const schema = SnowplowHelper.generateSchema('', 'group', true);

      dataCopy.group_id = newGroupId;
      dataCopy.type = groupType;
      const serverSideEligibleProviders = this.config.serverSideEligibleProviders;
      const eventData = SnowplowHelper.prepareData(
        userId,
        visitorId,
        dataCopy,
        serverSideEligibleProviders,
      );

      this.log('group snowplow', eventCategory, eventName, schema, eventData);

      SnowplowHelper.snowplowEvent(eventCategory, eventName, schema, eventData);
    }

    data = Helper.deepCopyMerge(this.getSinglePurposeIdentifiers(), dataCopy);

    this.activeProviders.forEach(provider => {
      if (!SnowplowHelper.isBotUserAgent()) {
        provider.group({ groupId, groupType, userId, visitorId, sessionId, data });
      }
    });
  }

  trackScrollEvent() {
    const scrollProps = scrollProperties();
    // Send only to snowplow for now
    if (SCROLL_PERCENTAGES.indexOf(scrollProps.percent) > -1) {
      this.track({
        targetProvider: 'snowplow',
        eventCategory: 'Page',
        eventName: 'Scroll',
        data: {
          ...scrollProps,
        },
      });
    }
  }

  trackDomEvent(event) {
    const { type } = event;
    const capitalizedEventType = type.charAt(0).toUpperCase() + type.slice(1);
    this.track({
      eventCategory: 'Dom',
      eventName: capitalizedEventType,
      data: {
        ...globalProperties(),
        ...clickSchemaProperties(event),
        event: eventProperties(event),
      },
    });
  }

  // prop (hash parameters):
  //   eventCategory
  //   eventName
  //   userId = optional
  //   data = optional
  //   callback = optional
  track(prop, isPii = false) {
    let data = Helper.deepCopyMerge({}, prop.data);
    // Try to get user ID from config if not passed in
    let userId = prop.userId || this.config.userId;
    const { eventCategory, eventName } = prop;

    if (!eventCategory || !eventName) {
      this.log('Must provide eventCategory and eventName');
      return;
    }

    AdFraudDetection.getAdFraudDetectionId();
    const visitorId = Visitor.getVisitorId();
    const pandaAdminId = this.config.pandaAdminId;
    const userRoleId = this.getUserRoleId();
    const sessionId = Session.getSessionId();

    if (this.currentPageView) {
      data.page_view_id = this.currentPageView.pageViewId();
    }

    data.impersonated_user_id = null;
    if (pandaAdminId && userId && String(pandaAdminId) !== String(userId)) {
      data.impersonated_user_id = userId;
      userId = pandaAdminId;
    }

    this.log(`track${isPii ? 'Pii' : ''}`, arguments);

    // Add version number to data
    data.gusto_analytics_version = this._version;

    // Add panda admin ID to data if available
    if (pandaAdminId) {
      data.panda_admin_id = pandaAdminId;
    }

    // Add user role ID to data if available and not part of data already
    if (!data.user_role_id && userRoleId) {
      data.user_role_id = userRoleId;
    }

    // Add isBot to property to detect bot status through useragent
    data.is_bot = isbot(getClientUserAgent());

    this.replaceDataUuidWithId(data, eventCategory, eventName);

    // Add referrer, path, url, and title to data
    // Add tracking cookies to data
    // Values in developer specified data take precedence
    data = Helper.deepCopyMerge(Helper.getPageInfo(), CookiesUtils.getTrackingCookies(), data);

    // Make a copy in case data is modified
    // Add session ID for snowplow event data
    const dataCopy = Helper.deepCopyMerge({}, data, { session_id: sessionId });
    if (isPii) {
      dataCopy.isPii = true;
    }
    if (prop.targetProvider) {
      dataCopy.targetProvider = prop.targetProvider;
    }

    if (this.config.snowplowEnabled) {
      let isCompany = false;
      let newUserId;
      const schema = SnowplowHelper.generateSchema(eventCategory, eventName, true);

      // Add type to properties if company event
      if (userId && userId[0] === 'C') {
        isCompany = true;
        dataCopy.type = 'company';
        newUserId = userId.substring(1);
      }

      const serverSideEligibleProviders = this.config.serverSideEligibleProviders;
      const eventData = SnowplowHelper.prepareData(
        isCompany ? newUserId : userId,
        visitorId,
        dataCopy,
        serverSideEligibleProviders,
      );

      this.log('track snowplow', eventCategory, eventName, schema, eventData);
      SnowplowHelper.snowplowEvent(eventCategory, eventName, schema, eventData);
    }

    const providers = isPii
      ? this.activeProviders.filter(p => p.isApprovedProvider())
      : this.activeProviders;

    let targetProviders = providers.filter(
      p => !DEFAULT_OMITTED_TRACKING_PROVIDERS.includes(p.providerName()),
    );
    // If the identify call is intended for one of [AmplitudeProvider, FullstoryProvider]
    // We will pass the data directly to target provider and skip other providers.

    if (prop.targetProvider) {
      const targetProvider = providers.find(p => p.providerName() === prop.targetProvider);
      targetProviders = targetProvider ? [targetProvider] : [];
    }

    data = Helper.deepCopyMerge(this.getSinglePurposeIdentifiers(), dataCopy);

    targetProviders.forEach(provider => {
      if (!SnowplowHelper.isBotUserAgent()) {
        provider.track({ eventCategory, eventName, userId, visitorId, sessionId, data });
      }
    });
  }

  // Same as track(), but only send to approved analytics tools
  //   eventCategory
  //   eventName
  //   userId = optional
  //   data = optional
  trackPii(prop) {
    this.track(prop, true);
  }

  // Track only snowplow, no other providers
  trackSnowplowOnly(prop) {
    const snowplowOnlyProps = { ...prop, targetProvider: 'snowplow' };
    this.track(snowplowOnlyProps, false, true);
  }

  triggerRevenueForecastingEvent(visitorId) {
    let firstSessionCookieSet = false;
    const cmsApplications = ['wp-talkshop', 'eng-blog', 'wordpress'];
    let parameters = { ...Helper.getPageInfo(), ...Helper.getTrackingParams() };
    if (!cmsApplications.includes(this.config.snowplowTrackerName)) {
      parameters.cookieReset = true;
    } else {
      firstSessionCookieSet = true;
    }
    parameters.visitor_id = visitorId;
    parameters = Helper.deepCopyMerge(CookiesUtils.getTrackingCookies(), parameters);
    parameters.url_query_params = Helper.getTrackingParams();
    parameters.non_attribution_cookies = CookiesUtils.getTrackingCookies();
    this.track({
      eventCategory: 'RevenueForecasting',
      eventName: 'NewUserLanded',
      data: {
        ...parameters,
      },
    });
    if (firstSessionCookieSet) {
      this.storeFirstSessionIdCookie(Session.getSessionId());
    }
  }

  // Wraps a provided function/callback with a call to track when invoked. props and isPii will be
  // passed directly to track, and the wrapped function's input/output interface will be preserved.
  wrapWithTracking(funcToWrap, prop, isPii = false) {
    return (...args) => {
      this.track(prop, isPii);
      return funcToWrap(...args);
    };
  }

  // TODO Delete this and update the pre-load script for a 1.0 release
  experimentEnrollment() {}

  // Push data to data layer
  dataLayer(data, isPii = false) {
    this.log('dataLayer', { data, isPii });

    // Tealium data layer can contain PII
    tealiumDataLayerPush(data);
  }

  dataLayerPii(data) {
    this.dataLayer(data, true);
  }

  // Provide gtmDataLayer alias while we transition to a service-agnostic name
  gtmDataLayer(data) {
    this.dataLayer(data);
  }

  // Tag Inspectlet session
  inspectletTagSession(tag) {
    this.log('inspectletTagSession tag = ', tag);

    InspectletHelper.tagSession(tag);
  }

  autoPageEvent() {
    return !(this.config.autoPageEvent === false);
  }

  scrollEventsEnabled() {
    return this.config.scrollEventsEnabled === true;
  }

  visibilityEventsEnabled() {
    return this.config.visibilityEventsEnabled === true;
  }

  getPrivacyPreferences() {
    return SnowplowHelper.addOnetrustCookies(CookiesUtils.getCookie(ONETRUST_COOKIE_NAME));
  }

  // Return user role ID if found
  getUserRoleId() {
    return (
      window.Payroll &&
      window.Payroll.Store &&
      window.Payroll.Store.user &&
      window.Payroll.Store.user.currentUserRole &&
      window.Payroll.Store.user.currentUserRole.get('id')
    );
  }

  getSinglePurposeIdentifiers() {
    const gexpId = Experimentation.getExperimentationId();
    const upsId = UserProfileService.getUserProfileServiceId();
    const userTargetId = UserTargeting.getUserTargetingId();

    return {
      gexp_id: gexpId,
      ups_id: upsId,
      user_target_id: userTargetId,
    };
  }

  replaceDataUuidWithId(data, eventCategory, eventName) {
    // traverse the data object recursively and replace any employee uuid with the corresponding id
    if (typeof data !== 'object' || data === null) return;
    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (typeof value === 'symbol') return;

        if (uuid4.valid(value) && !Helper.EXCLUDED_UUID_EVENT_CATEGORIES.includes(eventCategory)) {
          const analyticsId = this.getAnalyticsId(value);
          if (analyticsId !== value && !key.toLowerCase().includes('uuid')) {
            // eslint-disable-next-line no-param-reassign
            data[key] = analyticsId;
            // eslint-disable-next-line no-param-reassign
            data[`replace_uuid_${key}`] = value;
            trackError({
              error: {
                name: 'UuidTrackDataTranslated',
                message: `Uuid value (${value}) passed as data to GustoAnalytics#track, but was translated. Please use the numeric analyticsId for tracking`,
              },
              custom: {
                key,
                value,
                eventCategory,
                eventName,
              },
            });
          } else if (
            [
              'companyId',
              'company_id',
              'employeeId',
              'employee_id',
              'contractorId',
              'contractor_id',
              'payrollId',
              'payroll_id',
              'formId',
              'form_id',
              'personalDocumentId',
              'personal_document_id',
              'personalDocumentRequestId',
              'personal_document_request_id',
            ].includes(key)
          ) {
            trackError({
              error: {
                name: 'UuidTrackDataNotTranslated',
                message: `Uuid value (${value}) passed as data to GustoAnalytics#track, but was not translated to a numeric ID. Please use the numeric analyticsId for tracking`,
              },
              custom: {
                key,
                value,
                eventCategory,
                eventName,
              },
            });
          }
        } else {
          this.replaceDataUuidWithId(value, eventCategory, eventName);
        }
      }
    });
  }

  getAnalyticsId(value) {
    const companyId = this.getCompanyAnalyticsId(value);
    if (companyId !== value) {
      return companyId;
    }
    const employeeId = this.getEmployeeAnalyticsId(value);
    if (employeeId !== value) {
      return employeeId;
    }
    return value;
  }

  // Return company ID if found else return uuid
  getCompanyAnalyticsId(uuid) {
    const company = window.Payroll && window.Payroll.Store && window.Payroll.Store.company;

    if (!company || typeof company.get !== 'function') {
      return uuid;
    }

    return company.get('id') === uuid ? company.get('analytics_id') : uuid;
  }

  // Return employee ID if found else return uuid
  getEmployeeAnalyticsId(uuid) {
    const employees = window.Payroll && window.Payroll.Store && window.Payroll.Store.employees;

    if (!employees || typeof employees.get !== 'function') {
      return uuid;
    }

    const employee = employees.get(uuid);
    return employee ? employee.get('analytics_id') : uuid;
  }

  storeFirstSessionIdCookie(firstSessionId) {
    CookiesUtils.setCookie(FIRST_SESSION_COOKIE_NAME, firstSessionId, 45);
  }

  getPaidTrackingParams(url) {
    return Helper.getTrackingParams(url);
  }
}

export default GustoAnalytics;
