import uuid from 'uuid4';

class PageView {
  constructor() {
    this.uuid = uuid();
  }

  pageViewId() {
    return this.uuid;
  }
}

export default PageView;
