import { BrowserFeaturesPlugin } from '@snowplow/browser-plugin-browser-features';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';
import { newTracker, trackStructEvent, trackPageView } from '@snowplow/browser-tracker';
import OptanonConsentCookies from '../../optanon_consent_cookies.json';
import { BOT_AGENTS } from '../constants';
import Experimentation from '../experiment_id';
import AdFraudDetection from '../fraud_id';
import UserProfileService from '../user_profile_service_id';
import UserTargeting from '../user_targeting_id';
import { buildCamel } from './build_helper';
import { getClientUserAgent, getWindowLocationSearch } from './window_helper';

let trackerName;
let trackingUrl;
let appId;
const botAgents = BOT_AGENTS.join('|');

export function forceHttps(url) {
  let newUrl = url;
  newUrl = newUrl.trim().replace(/\s/g, '');

  // Checking if the URL String contains any protocol by checking occurrence of '://'
  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  // If URL String contains any protocol other than HTTPS, we replace it with HTTPS.
  if (!/^(ht)tps:\/\//i.test(newUrl)) {
    newUrl = url.replace(/(^\w+:|^)\/\//, '');
    return `https://${newUrl}`;
  }

  return newUrl;
}

export function isBotUserAgent() {
  const userAgent = getClientUserAgent();
  const botRegex = new RegExp(`(${botAgents})`, 'i');
  return botRegex.test(userAgent);
}

export function init(
  configTrackerName,
  configTrackingUrl,
  configAppId,
  newSnowplowTrackerFn = newTracker,
  timezonePlugin = TimezonePlugin(),
  browserFeaturesPlugin = BrowserFeaturesPlugin(),
) {
  if (!configTrackerName || !configTrackingUrl || !configAppId) {
    return;
  }

  trackerName = configTrackerName;
  trackingUrl = forceHttps(configTrackingUrl);
  appId = configAppId;

  newSnowplowTrackerFn(trackerName, trackingUrl, {
    appId,
    encodeBase64: true,
    eventMethod: 'post',
    stateStorageStrategy: 'cookieAndLocalStorage',
    plugins: [browserFeaturesPlugin, timezonePlugin],
  });
}

export function eventCategories() {
  const values = {
    VISITOR_EVENT: 'VisitorEvent',
    EXPERIMENT: 'Experiment',
  };

  return values;
}

const contexts = (data, schema) =>
  data == null
    ? null
    : [
        {
          data,
          schema,
        },
      ];

export function snowplowEvent(
  eventCategory,
  eventName,
  schema,
  data,
  trackSnowplowStructEventFn = trackStructEvent,
) {
  if (isBotUserAgent()) {
    return;
  }
  try {
    trackSnowplowStructEventFn({
      category: eventCategory,
      action: eventName,
      context: contexts(data, schema),
    });
  } catch {
    // do nothing
  }
}

export function pageView(customTitle, schema, data, trackSnowplowPageViewFn = trackPageView) {
  if (isBotUserAgent()) {
    return;
  }
  const search = getWindowLocationSearch();
  if (search) {
    // eslint-disable-next-line no-param-reassign
    data.search = data.search || search;
  }
  trackSnowplowPageViewFn({ title: customTitle, context: contexts(data, schema) });
}

// Format OneTrust cookie data
export function addOnetrustCookies(optanonConsent) {
  const onetrustInfo = {};

  if (optanonConsent && Object.keys(optanonConsent).length !== 0) {
    // Create JSON object from OneTrust cookie data that is formatted as a url search param string
    const oneTrustCookieData = Object.fromEntries(new URLSearchParams(optanonConsent));

    Object.entries(oneTrustCookieData).forEach(([key, value]) => {
      if (Object.keys(OptanonConsentCookies).includes(key)) {
        if (key === 'groups') {
          value.split(',').forEach(groupValue => {
            const [cookieType, optValue] = groupValue.split(':');
            if (Object.keys(OptanonConsentCookies.groups).includes(cookieType)) {
              onetrustInfo[OptanonConsentCookies.groups[cookieType]] =
                optValue === '1' ? 'opt_in' : 'opt_out';
            }
          });
        } else {
          onetrustInfo[OptanonConsentCookies[key]] = value;
        }
      }
    });
  }

  return onetrustInfo && Object.keys(onetrustInfo).length !== 0 ? onetrustInfo : null;
}

// Returns flattened object consists of properties, visitor_id, and user_id (if available)
export function prepareData(userId, visitorId, properties, serverSideEligibleProviders = []) {
  const data = properties;

  // Add visitor_id
  data.visitor_id = visitorId;

  // Add user_id if available
  if (userId) {
    data.user_id = userId;
  }

  const onetrustCookieData = addOnetrustCookies(data.optanon_consent);
  if (onetrustCookieData) {
    data.onetrust = onetrustCookieData;
  }
  // Delete unformatted optanon_consent value from data
  // Intentionally commenting delete code to assit in Identity investigation where consent ID is missing
  // delete data.optanon_consent;

  const gafdId = AdFraudDetection.getAdFraudDetectionId();
  const gexpId = Experimentation.getExperimentationId();
  const upsId = UserProfileService.getUserProfileServiceId();
  const userTargetId = UserTargeting.getUserTargetingId();

  if (gafdId) {
    data.gafd_id = gafdId;
  }

  if (gexpId) {
    data.gexp_id = gexpId;
  }

  if (upsId) {
    data.ups_id = upsId;
  }

  if (userTargetId) {
    data.user_target_id = userTargetId;
  }

  data.server_side_eligible_providers = serverSideEligibleProviders;

  return data;
}

const downcaseFirstLetter = str => str[0].toLowerCase() + str.slice(1);

// Returns generated schema based on categoryName and eventName
// schema = 'com.gusto/<categoryName + eventName>/<version> if hasData = true
//        = 'com.gusto/noData/<version>' if hasData = false
export function generateSchema(categoryName, eventName, hasData, version = 1) {
  const schemaName = hasData ? buildCamel(categoryName, eventName) : 'noData';
  return `com.gusto/${downcaseFirstLetter(schemaName)}/${version}`;
}
