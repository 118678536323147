import { parseConfig } from './config_parser.ts';
import GustoAnalytics from './gusto_analytics';
import init from './init';

// Set up config in <script> block before including GustoAnalytics in a separate <script src=...>
const config = parseConfig() || {};
const gustoAnalyticsSingleton = new GustoAnalytics(config);

gustoAnalyticsSingleton.loadAnalytics();

// init() will be called automatically unless window.autoInit = false
if (config.autoInit !== false) {
  init(gustoAnalyticsSingleton);
}

export default gustoAnalyticsSingleton;
