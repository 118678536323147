import { USER_TARGETING_COOKIE_NAME, USER_TARGETING_ORIGIN_HASH_COOKIE_NAME } from './constants';
import * as CookiesUtils from './cookies_utils';
import Identifier from './identifiers';

class UserTargeting {
  static getUserTargetingId() {
    // This does nothing if valid Targeting cookie has been set already
    Identifier.authorizeIdentifier(USER_TARGETING_COOKIE_NAME);

    return CookiesUtils.getCookie(USER_TARGETING_COOKIE_NAME);
  }

  static getUserTargetingIdOriginHash() {
    const cookie = CookiesUtils.getCookie(USER_TARGETING_ORIGIN_HASH_COOKIE_NAME);

    return cookie && decodeURIComponent(cookie);
  }

  static checkResetUserTargetingId(userId) {
    return Identifier.checkResetIdentifier(
      userId,
      USER_TARGETING_ORIGIN_HASH_COOKIE_NAME,
      USER_TARGETING_COOKIE_NAME,
    );
  }
}

export default UserTargeting;
