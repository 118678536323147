"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIRST_SESSION_COOKIE_NAME = exports.ONETRUST_COOKIE_NAME = exports.SCROLL_PERCENTAGES = exports.DEFAULT_OMITTED_TRACKING_PROVIDERS = exports.UNWANTED_FULLSTORY_TRACK_CALL_PROPERTIES = exports.USER_TARGETING_ORIGIN_HASH_COOKIE_NAME = exports.USER_TARGETING_COOKIE_NAME = exports.USER_PROFILE_SERVICE_ORIGIN_HASH_COOKIE_NAME = exports.USER_PROFILE_SERVICE_COOKIE_NAME = exports.EXPERIMENT_ORIGIN_HASH_COOKIE_NAME = exports.EXPERIMENT_COOKIE_NAME = exports.AD_FRAUD_ORIGIN_HASH_COOKIE_NAME = exports.AD_FRAUD_COOKIE_NAME = exports.TRACKING_COOKIE_PREFIX = exports.VISITOR_ID_ORIGIN_HASH_COOKIE_NAME = exports.SESSION_START_NEEDED_COOKIE_NAME = exports.SESSION_COOKIE_NAME = exports.VISITOR_COOKIE_NAME = exports.LONG_TERM_COOKIE_EXPIRATION_IN_MIN = exports.GROUP_ID_TYPE_MAP = exports.DEFAULT_COOKIE_EXPIRATION_IN_MIN = exports.DEFAULT_COOKIE_EXPIRATION_IN_DAYS = exports.BOT_AGENTS = void 0;
const MINUTES_PER_DAY = 24 * 60;
exports.BOT_AGENTS = [
    'googlebot',
    'bot',
    'Googlebot-Mobile',
    'Googlebot-Image',
    'Google favicon',
    'Mediapartners-Google',
    'bingbot',
    'slurp',
    'java',
    'wget',
    'curl',
    'bot',
    'datadog',
    'nginx',
    'kube',
    'Go-http-client',
    'Iframely',
    'chrome-lighthouse',
    'health-check',
    'lighthouse',
];
exports.DEFAULT_COOKIE_EXPIRATION_IN_DAYS = 90;
exports.DEFAULT_COOKIE_EXPIRATION_IN_MIN = exports.DEFAULT_COOKIE_EXPIRATION_IN_DAYS * MINUTES_PER_DAY;
exports.GROUP_ID_TYPE_MAP = {
    A: 'accountantLead',
    C: 'company',
    L: 'companyLead',
};
exports.LONG_TERM_COOKIE_EXPIRATION_IN_MIN = 20 * 365 * MINUTES_PER_DAY;
exports.VISITOR_COOKIE_NAME = 'visitor_id';
exports.SESSION_COOKIE_NAME = 'gsid';
exports.SESSION_START_NEEDED_COOKIE_NAME = 'session_start_needed';
exports.VISITOR_ID_ORIGIN_HASH_COOKIE_NAME = 'visitor_id_origin_h';
exports.TRACKING_COOKIE_PREFIX = 'gst_';
exports.AD_FRAUD_COOKIE_NAME = 'gafd_id';
exports.AD_FRAUD_ORIGIN_HASH_COOKIE_NAME = 'gafd_id_origin_h';
exports.EXPERIMENT_COOKIE_NAME = 'gexp_id';
exports.EXPERIMENT_ORIGIN_HASH_COOKIE_NAME = 'gexp_id_origin_h';
exports.USER_PROFILE_SERVICE_COOKIE_NAME = 'ups_id';
exports.USER_PROFILE_SERVICE_ORIGIN_HASH_COOKIE_NAME = 'ups_id_origin_h';
exports.USER_TARGETING_COOKIE_NAME = 'user_target_id';
exports.USER_TARGETING_ORIGIN_HASH_COOKIE_NAME = 'user_target_id_origin_h';
exports.UNWANTED_FULLSTORY_TRACK_CALL_PROPERTIES = [
    'gusto_analytics_version',
    'gafd_id',
    'gexp_id',
    'has_signed_in',
    'is_bot',
    'optanon_consent',
    'page_view_id',
    'ups_id',
    'userId',
    'user_id',
    'user_role_id',
    'user_target_id',
    'visitorId',
];
// This exists because by default, we didn't want to send all .track calls to
// Intercom. They have a limit of 120 unique event names, after which events
// have to be archived to make room for more.
exports.DEFAULT_OMITTED_TRACKING_PROVIDERS = [];
exports.SCROLL_PERCENTAGES = [25, 50, 75, 100];
exports.ONETRUST_COOKIE_NAME = 'OptanonConsent';
exports.FIRST_SESSION_COOKIE_NAME = 'fgsid';
