import * as CookiesUtils from './cookies_utils';
import AdFraudDetection from './fraud_id';
import * as windowHelper from './helpers/window_helper';
import Visitor from './visitor';

const Refcodes = {
  // SEO if referral from google, bing, yahoo, aol, ask = catches ~99.8% of all organic visits
  SEO_DOMAINS: [
    'www.google.',
    'www.bing.com',
    'www.ask.com',
    'search.yahoo.',
    'search.aol.',
    'duckduckgo.com',
  ],

  // Returns object of cookie values if sendToAnalytics = true
  // Else return {}
  referralCookies() {
    // eslint-disable-next-line no-use-before-define
    const referrerDetails = getReferralInformation();
    let sendToAnalytics = true;
    let debugRefcodes = false;

    // Capybara/poltergeist doesn't like this!!!!??? WHY!?!?!
    if (
      referrerDetails.referringDomain.indexOf('gusto') >= 0 ||
      referrerDetails.referringDomain.indexOf('zenpayroll') >= 0
    ) {
      // TODO: remove this once we figure out why source and landing_page are null for 10% of leads
      // return {};
      debugRefcodes = true;
    }

    AdFraudDetection.getAdFraudDetectionId();
    const visitorId = Visitor.getVisitorId();

    // Check if source already exists. If not, collect all possible data
    // If found, do nothing
    if (!CookiesUtils.getCookie('so')) {
      // Set cookie values here
      CookiesUtils.setCookie('so', referrerDetails.source);
      CookiesUtils.setCookie('lp', referrerDetails.landingPage, null, 4096);
      CookiesUtils.setCookie('ca', referrerDetails.campaign);
      CookiesUtils.setCookie('co', referrerDetails.content);
      CookiesUtils.setCookie('mt', referrerDetails.matchType);
      CookiesUtils.setCookie('kw', referrerDetails.keyword);
      CookiesUtils.setCookie('cr', referrerDetails.creative);
      CookiesUtils.setCookie('gc', referrerDetails.gclid);
      CookiesUtils.setCookie('pl', referrerDetails.placement);
      CookiesUtils.setCookie('dv', referrerDetails.device);
      CookiesUtils.setCookie('ne', referrerDetails.network);
      CookiesUtils.setCookie('me', referrerDetails.medium);
      CookiesUtils.setCookie('ad', referrerDetails.adgroup);
      CookiesUtils.setCookie('ms', referrerDetails.msclkid);
      CookiesUtils.setCookie('ru', referrerDetails.referringUrl, null, 4096);
    }
    // TODO: remove this once we figure out why source and landing_page are null for 10% of leads
    // All cookie values are for debugging purposes so we shouldn't compare it to old values
    // Setting sendToAnalytics = false and bypass comparison process. This will allow us to
    // send current values as debug_ properties
    else {
      sendToAnalytics = false;
    }

    // Send to snowplow, only on first visit
    if (sendToAnalytics && !debugRefcodes) {
      return {
        visitor_id: visitorId,
        source: referrerDetails.source,
        landing_page: referrerDetails.landingPage,
        campaign: referrerDetails.campaign,
        content: referrerDetails.content,
        match_type: referrerDetails.matchType,
        keyword: referrerDetails.keyword,
        creative: referrerDetails.creative,
        gclid: referrerDetails.gclid,
        placement: referrerDetails.placement,
        device: referrerDetails.device,
        network: referrerDetails.network,
        medium: referrerDetails.medium,
        adgroup: referrerDetails.adgroup,
        msclkid: referrerDetails.msclkid,
        referring_url: referrerDetails.referringUrl,
      };
    }
    // TODO: remove this once we figure out why source and landing_page are null for 10% of leads
    return {
      debug_source: CookiesUtils.getCookie('so') || referrerDetails.source,
      debug_landing_page: CookiesUtils.getCookie('lp') || referrerDetails.landingPage,
      debug_campaign: CookiesUtils.getCookie('ca') || referrerDetails.campaign,
      debug_content: CookiesUtils.getCookie('co') || referrerDetails.content,
      debug_match_type: CookiesUtils.getCookie('mt') || referrerDetails.matchType,
      debug_keyword: CookiesUtils.getCookie('kw') || referrerDetails.keyword,
      debug_creative: CookiesUtils.getCookie('cr') || referrerDetails.creative,
      debug_gclid: CookiesUtils.getCookie('gc') || referrerDetails.gclid,
      debug_placement: CookiesUtils.getCookie('pl') || referrerDetails.placement,
      debug_device: CookiesUtils.getCookie('dv') || referrerDetails.device,
      debug_network: CookiesUtils.getCookie('ne') || referrerDetails.network,
      debug_medium: CookiesUtils.getCookie('me') || referrerDetails.medium,
      debug_adgroup: CookiesUtils.getCookie('ad') || referrerDetails.adgroup,
      debug_msclkid: CookiesUtils.getCookie('ms') || referrerDetails.msclkid,
      debug_referring_url: CookiesUtils.getCookie('ru') || referrerDetails.referringUrl,
    };
  },

  // TODO: remove this once we figure out why source and landing_page are null for 10% of leads
  isDebugRefcodes(refcodes) {
    return Object.prototype.hasOwnProperty.call(refcodes, 'debug_source');
  },

  getReferringDomainFromReferrer(referringUrl) {
    return String(referringUrl.split('/')[2]);
  },

  // Function takes 1+ parameter names and returns the value of the first one that exists
  getFirstValueForParamNames(/* params... */) {
    const paramNames = Array.prototype.slice.call(arguments);

    // Capybara/poltergeist/whatever test this barfs on [].find
    while (paramNames.length) {
      const paramName = paramNames.shift();
      const paramValue = this.getParameterByName(paramName);

      if (paramValue) {
        return paramValue;
      }
    }
  },

  // Function takes 1 argument: the parameter name you're looking for in the URL.
  // If found, it returns the value of the param.
  getParameterByName(paramName) {
    const queryRegexString = `[\\?&]${paramName}=([^&#]*)`;
    const regex = new RegExp(queryRegexString, 'i');
    const results = regex.exec(windowHelper.getWindowLocationSearch());

    if (results === null) {
      return false;
    }
    window.location.search.replace(queryRegexString, '');

    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
};

export function getReferralInformation() {
  let source;
  let campaign = '';
  let content = '';
  let matchType = '';
  let keyword = '';
  let creative = '';
  let gclid = '';
  let placement = '';
  let device = '';
  let network = '';
  let medium = '';
  let adgroup = '';
  let msclkid = '';
  const sendToAnalytics = true;
  const debugRefcodes = false;

  // If navigating around our sites, don't run the referralCookies again
  const referringUrl = windowHelper.getDocumentReferrer();
  const referringDomain = Refcodes.getReferringDomainFromReferrer(referringUrl);

  // Set Landing Page Cookie. Key => lp, Value => url of first page view
  const landingPage = windowHelper.getWindowLocationHref();

  // If there is a utm_source in a URL, we can assume we put them there
  // and they are part of a campaign or link building effort we are running.
  // In those cases there will/should always be a utm_source at minimum.

  // Take utm_source before vt_source
  const sourceValue = Refcodes.getFirstValueForParamNames('utm_source', 'vt_source');

  if (sourceValue) {
    source = sourceValue;
    campaign = Refcodes.getFirstValueForParamNames('utm_campaign', 'vt_campaign'); // campaign
    content = Refcodes.getFirstValueForParamNames('utm_content', 'vt_content'); // content
    matchType = Refcodes.getFirstValueForParamNames('matchtype'); // matchtype
    keyword = Refcodes.getFirstValueForParamNames('keyword'); // keyword
    creative = Refcodes.getFirstValueForParamNames('creative'); // creative
    gclid = Refcodes.getFirstValueForParamNames('gclid'); // google ad click ID
    placement = Refcodes.getFirstValueForParamNames('placement'); // google display ad url
    device = Refcodes.getFirstValueForParamNames('device'); // device
    network = Refcodes.getFirstValueForParamNames('network');
    medium = Refcodes.getFirstValueForParamNames('medium');
    adgroup = Refcodes.getFirstValueForParamNames('adgroup');
    msclkid = Refcodes.getFirstValueForParamNames('msclkid');
  }
  // If no params in URL, but we have a referral URL, it's either SEO or Inbound/Referral
  else if (referringUrl) {
    if (Refcodes.SEO_DOMAINS.find(domain => referringUrl.indexOf(domain) > -1)) {
      // If in the seoDomains array, it's SEO
      // Set source => SEO
      // inArray function returns -1 if not found in array. Thus > -1 = something matched
      source = 'SEO';
    }
    // If referral and not search engine, it's inbound
    // Set source => Inbound Link && network => referring url
    else {
      source = 'Inbound Referral';
    }
  }
  // If no utm_source && no referring URL, it's a Direct visit
  // Set source => Direct
  else {
    source = 'Direct';
  }

  return {
    source,
    campaign,
    content,
    matchType,
    keyword,
    creative,
    gclid,
    placement,
    device,
    network,
    medium,
    adgroup,
    msclkid,
    sendToAnalytics,
    debugRefcodes,
    referringUrl,
    referringDomain,
    landingPage,
  };
}

export default Refcodes;
