// Queue events before window.utag is ready
const eventQueue = [];

const sendTealiumEvent = ([method, args], { utag } = window) => utag && utag[method](...args);

export function dataLayerPush(dataLayerObj, win = window) {
  win.utag_data = { ...win.utag_data, ...dataLayerObj }; // eslint-disable-line no-param-reassign
}

export function drainQueue(queue = eventQueue, win = window) {
  // Push will now send straight to window.utag
  queue.push = sendTealiumEvent; // eslint-disable-line no-param-reassign
  while (queue.length) sendTealiumEvent(queue.shift(), win);
}

export function loadUtag(
  accountProfileEnv,
  appendTarget = document.head,
  onload = drainQueue,
  win = window,
) {
  // Original snippet:
  // (function(a,b,c,d){
  //   a=`//tags.tiqcdn.com/utag/${accountProfileEnv}/utag.js`;
  //   b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
  //   a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
  // })();

  // https://docs.tealium.com/platforms/javascript/settings/#noview
  win.utag_cfg_ovrd = { ...win.utag_cfg_ovrd, noview: true }; // eslint-disable-line no-param-reassign
  const script = document.createElement('script');
  script.async = true;
  script.setAttribute('data-ot-ignore', '');
  script.onload = () => onload(); // addition for event queue
  script.src = `https://tags.tiqcdn.com/utag/${accountProfileEnv}/utag.js`;
  appendTarget.appendChild(script);
}

export const utag = (method, args, queue = eventQueue) => queue.push([method, args]);
