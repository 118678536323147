import { SESSION_COOKIE_NAME, SESSION_START_NEEDED_COOKIE_NAME } from './constants';
import * as CookiesUtils from './cookies_utils';

const getSessionIdFromCookie = () => CookiesUtils.getCookie(SESSION_COOKIE_NAME);

let timer;

const COOKIE_TIMEOUT_IN_MINUTES = 30;
const SESSION_RELOAD_TIMEOUT_IN_MS = 3_600_000; // 60 min in milliseconds

const Session = {
  // Generate new session ID if no valid session ID exist
  // Re-write session ID with new exp time if valid session ID exist
  updateSessionId() {
    const sessionId = getSessionIdFromCookie() || new Date().getTime().toString();
    CookiesUtils.setCookie(SESSION_COOKIE_NAME, sessionId, 30);
  },

  getSessionId() {
    this.updateSessionId();
    return getSessionIdFromCookie();
  },

  sessionStartNeeded() {
    return !getSessionIdFromCookie() || typeof this.getSessionStartNeededCookie() !== 'undefined';
  },

  setSessionStartNeeded() {
    if (!getSessionIdFromCookie()) {
      CookiesUtils.setCookie(SESSION_START_NEEDED_COOKIE_NAME, 'true', COOKIE_TIMEOUT_IN_MINUTES);
      localStorage.setItem(SESSION_START_NEEDED_COOKIE_NAME, 'true');
    }
  },

  clearSessionStartNeeded() {
    CookiesUtils.deleteCookie(SESSION_START_NEEDED_COOKIE_NAME);
    localStorage.removeItem(SESSION_START_NEEDED_COOKIE_NAME);
  },

  // Helper method for stubbing in specs
  getSessionStartNeededCookie() {
    return CookiesUtils.getCookie(SESSION_START_NEEDED_COOKIE_NAME);
  },

  setSessionTimeout() {
    function timeoutAction() {
      // We are going to add a nonsense query parameter to get around the page cache
      const url = URL.parse(window.location.href);
      const params = url.searchParams === undefined ? new URLSearchParams() : url.searchParams;
      params.set('cache_reset', new Date().getTime());
      window.location.search = params.toString(); // causes reload due to param change
    }

    function TimeoutEvent() {
      if (typeof timer !== 'undefined') {
        timer = clearTimeout(timer); // reset as soon as interaction happens
      }
      timer = setTimeout(timeoutAction, SESSION_RELOAD_TIMEOUT_IN_MS);
    }

    // capturing all click, touch, mousemove, and keypress events
    window.addEventListener('click', TimeoutEvent, false);
    window.addEventListener('keypress', TimeoutEvent, false);
    window.addEventListener('mousemove', TimeoutEvent, false);
    window.addEventListener('touchstart', TimeoutEvent, false);
  },
};

export default Session;
