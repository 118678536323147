import * as CookiesUtils from './cookies_utils';
import * as Helper from './helpers/helper';
import * as WindowHelper from './helpers/window_helper';
import Refcodes from './refcodes';
import Session from './session';

function firePageEvent(gustoAnalyticsSingleton, properties, extraProperties = {}) {
  let pageName = Helper.constructPageName();

  // If pageName is unknown, don't fire auto page event
  if (pageName !== '') {
    gustoAnalyticsSingleton.log('auto page event');

    // If framework/blog/ask-gusto/help center/zp, don't use full page name as event name
    // It'll create too many event names!!
    if (Helper.isCategorizedPage(pageName)) {
      properties.page_name = Helper.getPageSubName(pageName); // eslint-disable-line no-param-reassign
      pageName = Helper.getPageCategory(pageName);
    }

    const friendName = Helper.getFriendName();

    if (friendName) {
      properties.page_name = friendName; // eslint-disable-line no-param-reassign
      pageName = Helper.INVITE_FRIENDS.NAME;
    }

    gustoAnalyticsSingleton.page({
      name: pageName,
      data: Helper.deepCopyMerge(properties, extraProperties, { auto: true }),
    });
  } else {
    console.error(`Unknown page name, not firing auto page event. Url: ${window.location.href}`); // eslint-disable-line no-console
  }
}

function autoPageEvent(gustoAnalyticsSingleton, properties) {
  const { extraPagePropParser } = gustoAnalyticsSingleton.config;

  if (typeof extraPagePropParser === 'function') {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => {
        firePageEvent(gustoAnalyticsSingleton, properties, extraPagePropParser());
      });
    } else {
      firePageEvent(gustoAnalyticsSingleton, properties, extraPagePropParser());
    }
  } else {
    firePageEvent(gustoAnalyticsSingleton, properties);
  }
}

function sendVisibilityEvents() {
  if (document.visibilityState) {
    window.GustoAnalytics.track({
      targetProvider: 'snowplow',
      eventCategory: 'Page',
      eventName: 'Visibility',
      data: {
        visibilityState: document.visibilityState,
      },
    });
  }
}

function handleVisibilityChange() {
  if (WindowHelper.isPageVisible()) {
    const refcodes = Refcodes.referralCookies();
    if (!Refcodes.isDebugRefcodes(refcodes)) {
      window.GustoAnalytics.identify({ data: refcodes });
    }
    document.removeEventListener(
      WindowHelper.pageVisibleEventName(),
      handleVisibilityChange,
      false,
    );
  }
}

function trackSessionStart(gustoAnalyticsSingleton, properties) {
  // Track session start
  if (Session.sessionStartNeeded()) {
    gustoAnalyticsSingleton.track({
      eventCategory: 'VisitorEvent',
      eventName: 'SessionStart',
      data: properties,
    });
    Session.clearSessionStartNeeded();
  }
}

function init(gustoAnalyticsSingleton) {
  const properties = WindowHelper.isPageVisible() ? Refcodes.referralCookies() : {};

  if (!WindowHelper.isPageVisible()) {
    document.addEventListener(WindowHelper.pageVisibleEventName(), handleVisibilityChange, false);
  }
  /*
   * Track page visibility change for time spend on page
   */
  if (gustoAnalyticsSingleton.visibilityEventsEnabled()) {
    document.addEventListener(WindowHelper.pageVisibleEventName(), sendVisibilityEvents, false);
  }
  gustoAnalyticsSingleton.analyticsReady(() => {
    // Identify user
    properties.has_signed_in = CookiesUtils.getCookie('has_signed_in') === 'true';

    trackSessionStart(gustoAnalyticsSingleton, properties);

    // Do not send debug refcodes as user properties
    if (Refcodes.isDebugRefcodes(properties)) {
      gustoAnalyticsSingleton.identify({ data: { has_signed_in: properties.has_signed_in } });
    } else {
      gustoAnalyticsSingleton.identify({ data: properties });
    }

    if (gustoAnalyticsSingleton.autoPageEvent()) {
      autoPageEvent(gustoAnalyticsSingleton, properties);
    }
    if (gustoAnalyticsSingleton.scrollEventsEnabled()) {
      document.addEventListener('scroll', () => {
        gustoAnalyticsSingleton.trackScrollEvent();
      });
    }

    // Drain preload event queue
    while (window.GustoAnalytics.length) {
      const methodCall = window.GustoAnalytics.shift();
      const methodName = methodCall[0];
      const args = Array.prototype.slice.call(methodCall, 1);

      gustoAnalyticsSingleton[methodName](...args);
    }

    window.GustoAnalytics = gustoAnalyticsSingleton;
    window.GustoAnalytics.initialize = !0;
    const body = document.getElementsByTagName('body')[0];
    if (body) body.className += ' gusto-analytics-loaded';
  });

  // set timeout to cause reload on session timeout
  Session.setSessionTimeout();
}

export { autoPageEvent, firePageEvent, sendVisibilityEvents, trackSessionStart };
export default init;
