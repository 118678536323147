"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const CookiesUtils = __importStar(require("./cookies_utils"));
const getSessionIdFromCookie = () => CookiesUtils.getCookie(constants_1.SESSION_COOKIE_NAME);
let timer;
const COOKIE_TIMEOUT_IN_MINUTES = 30;
const SESSION_RELOAD_TIMEOUT_IN_MS = 3_600_000; // 60 min in milliseconds
const Session = {
    // Generate new session ID if no valid session ID exist
    // Re-write session ID with new exp time if valid session ID exist
    updateSessionId() {
        const sessionId = getSessionIdFromCookie() || new Date().getTime().toString();
        CookiesUtils.setCookie(constants_1.SESSION_COOKIE_NAME, sessionId, 30);
    },
    getSessionId() {
        this.updateSessionId();
        return getSessionIdFromCookie();
    },
    sessionStartNeeded() {
        return !getSessionIdFromCookie() || typeof this.getSessionStartNeededCookie() !== 'undefined';
    },
    setSessionStartNeeded() {
        if (!getSessionIdFromCookie()) {
            CookiesUtils.setCookie(constants_1.SESSION_START_NEEDED_COOKIE_NAME, 'true', COOKIE_TIMEOUT_IN_MINUTES);
            localStorage.setItem(constants_1.SESSION_START_NEEDED_COOKIE_NAME, 'true');
        }
    },
    clearSessionStartNeeded() {
        CookiesUtils.deleteCookie(constants_1.SESSION_START_NEEDED_COOKIE_NAME);
        localStorage.removeItem(constants_1.SESSION_START_NEEDED_COOKIE_NAME);
    },
    // Helper method for stubbing in specs
    getSessionStartNeededCookie() {
        return CookiesUtils.getCookie(constants_1.SESSION_START_NEEDED_COOKIE_NAME);
    },
    setSessionTimeout() {
        function timeoutAction() {
            // We are going to add a nonsense query parameter to get around the page cache
            const url = URL.parse(window.location.href);
            const params = url?.searchParams === undefined ? new URLSearchParams() : url.searchParams;
            params.set('cache_reset', String(new Date().getTime()));
            window.location.search = params.toString(); // causes reload due to param change
        }
        function TimeoutEvent() {
            if (typeof timer !== 'undefined') {
                timer = clearTimeout(timer); // reset as soon as interaction happens
            }
            timer = setTimeout(timeoutAction, SESSION_RELOAD_TIMEOUT_IN_MS);
        }
        // capturing all click, touch, mousemove, and keypress events
        window.addEventListener('click', TimeoutEvent, false);
        window.addEventListener('keypress', TimeoutEvent, false);
        window.addEventListener('mousemove', TimeoutEvent, false);
        window.addEventListener('touchstart', TimeoutEvent, false);
    },
};
exports.default = Session;
