"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXCLUDED_UUID_EVENT_CATEGORIES = exports.INVITE_FRIENDS = void 0;
exports.isZpPage = isZpPage;
exports.isOtherGcorpPage = isOtherGcorpPage;
exports.isGcorpPage = isGcorpPage;
exports.constructPageName = constructPageName;
exports.getFriendName = getFriendName;
exports.getPageCategory = getPageCategory;
exports.getPageSubName = getPageSubName;
exports.isCategorizedPage = isCategorizedPage;
exports.getTrackingParams = getTrackingParams;
exports.getPageInfo = getPageInfo;
exports.deepCopy = deepCopy;
exports.deepCopyMerge = deepCopyMerge;
exports.removeEmpty = removeEmpty;
exports.mergeMetaData = mergeMetaData;
exports.removeUnsupportedFullStoryProperties = removeUnsupportedFullStoryProperties;
exports.removePiiEntries = removePiiEntries;
exports.removeUnwantedFullStoryProperties = removeUnwantedFullStoryProperties;
const constants_1 = require("../constants");
const windowHelper = __importStar(require("./window_helper"));
const DOMAIN_WHITELIST = ['gusto.', 'zenpayroll.', 'gusto-dev.', 'gusto-staging.', 'gusto-demo.'];
const ZP_SUBDOMAIN_WHITELIST = ['manage.', 'app.'];
const DCLID = 'dclid';
const FBCLID = 'fbclid';
const GCLID = 'gclid';
const GTM_PREFIX = 'gtm_';
const GUSTO_TRACKING_CODE = 'idgm';
const INVITE_TOKEN = 'invite_token';
const IRCLICKID = 'irclickid';
const MSCLKID = 'msclkid';
const REFERRAL_TOKEN = 'referral_token';
const UTM_PREFIX = 'utm_';
const VT_PREFIX = 'vt_';
const PREFIXES = [GTM_PREFIX, UTM_PREFIX, VT_PREFIX];
const IDS = [
    DCLID,
    FBCLID,
    GCLID,
    GUSTO_TRACKING_CODE,
    INVITE_TOKEN,
    IRCLICKID,
    MSCLKID,
    REFERRAL_TOKEN,
];
exports.INVITE_FRIENDS = {
    NAME: 'gcorp invite friends',
    PATH: '/invite/friends/',
};
exports.EXCLUDED_UUID_EVENT_CATEGORIES = ['CommandBar'];
// These are not considered gcorp pages even though they are nested under gcorp
const GCORP_OTHER_PROP_PATH = [
    '/framework/',
    '/company-news/',
    '/ask-gusto/',
    '/partner-resources/',
    exports.INVITE_FRIENDS.PATH,
];
function isZpPage(hostname, port) {
    const subdomainAndDomainMatch = ZP_SUBDOMAIN_WHITELIST.some(subdomain => DOMAIN_WHITELIST.some(domain => hostname.startsWith(`${subdomain}${domain}`)));
    const isLocalHost = hostname === 'localhost' && port === '3000';
    return subdomainAndDomainMatch || isLocalHost;
}
// Check if framework/company-news/ask-gusto
// Only check this if hostname is valid gcorp hostname
// Note that framework/company-news/ask-gusto/partner-resources are NOT considered Gcorp pages
function isOtherGcorpPage(path) {
    return GCORP_OTHER_PROP_PATH.some(propPath => path.startsWith(propPath));
}
function isGcorpPage(hostname, path, port) {
    const isLocalDevEnv = hostname === 'localhost' && port === '3001';
    return ((DOMAIN_WHITELIST.find(d => hostname.startsWith(d)) !== undefined || isLocalDevEnv) &&
        !isOtherGcorpPage(path));
}
const HOSTNAME_START_TO_PREFIX_MAP = {
    'credits.gusto.': 'credits',
    'engineering.gusto.': 'engineering',
    'framework.gusto-dev.': 'framework',
    'framework.gusto.': 'framework',
    'get.gusto.': 'webflow',
    'go.gusto.': 'marketo',
    'support.gusto.': 'support',
};
function prefixFromHostname(hostname) {
    const match = Object.entries(HOSTNAME_START_TO_PREFIX_MAP).find(([start]) => hostname.startsWith(start));
    return match && match[1];
}
function getPrefix(path) {
    const hostname = windowHelper.getWindowLocationHostname();
    const port = windowHelper.getWindowLocationPort();
    return (prefixFromHostname(hostname) ||
        (isZpPage(hostname, port) && 'zp') ||
        (isGcorpPage(hostname, path, port) && 'gcorp') ||
        '');
}
function nameFromPath(path, prefix) {
    const pathLevels = path.split('/').filter(Boolean).join(' ');
    const name = pathLevels.split('.')[0] || 'home';
    return [prefix, name].filter(Boolean).join(' ');
}
function constructPageName() {
    const path = windowHelper.getWindowLocationPathname();
    const prefix = getPrefix(path);
    const defaultName = document.title ? document.title : path;
    // Update 20250328 now retuning the full url if it's not a gcorp page
    // previously it would return '' which makes page load not fire.
    return !prefix && !isOtherGcorpPage(path) ? defaultName : nameFromPath(path, prefix);
}
// Get the friend name for the path
function getFriendName() {
    const friendName = windowHelper.getWindowLocationPathname().split(exports.INVITE_FRIENDS.PATH);
    return friendName.length > 1 && friendName[1];
}
// Return first word in pageName constructed using constructPageName()
// Return null if pageName is not a string
function getPageCategory(pageName) {
    return typeof pageName === 'string' ? pageName.split(' ')[0] : null;
}
// Return pageName minus the category (pageName constructed using constructPageName())
// Return null if pageName is not a string
// Return 'home' if subName is empty
function getPageSubName(pageName) {
    if (typeof pageName !== 'string') {
        return null;
    }
    const subName = pageName.split(' ').slice(1).join(' ');
    return subName || 'home';
}
const CATEGORIZED_PAGES = [
    'framework',
    'company-news',
    'ask-gusto',
    'partner-resources',
    'support',
    'marketo',
    'zp',
    'engineering',
];
// Return true if pageName, constructed using constructPageName(), is a page from framework/helper center/blog
// Return false otherwise
// Return null if pageName is not a string
function isCategorizedPage(pageName) {
    if (typeof pageName !== 'string') {
        return null;
    }
    const pageCategory = getPageCategory(pageName);
    if (!pageCategory) {
        return false;
    }
    return CATEGORIZED_PAGES.indexOf(pageCategory) !== -1;
}
function startsWithPrefix(paramName) {
    return PREFIXES.findIndex(prefix => paramName.startsWith(prefix)) !== -1;
}
function isPaidId(paramName) {
    return IDS.includes(paramName);
}
// Extract all tracking params and values (utm_*, vt_*, gclid) and return an object
function getTrackingParams(url = windowHelper.getWindowLocationHref()) {
    const retObj = {};
    if (url) {
        const queryString = url.split('?');
        if (queryString[1]) {
            const params = queryString[1].split('&');
            params
                .map(param => param.split('='))
                .forEach(([k, val]) => {
                const key = k.toLowerCase();
                if (startsWithPrefix(key) || isPaidId(key)) {
                    retObj[key] = val;
                }
            });
        }
    }
    return retObj;
}
// Get referrer, path, url, and title and return them as an object
function getPageInfo() {
    const data = {};
    const referrer = windowHelper.getDocumentReferrer();
    const path = windowHelper.getWindowLocationPathname();
    const url = windowHelper.getWindowLocationHref();
    const title = windowHelper.getDocumentTitle();
    if (referrer) {
        data.referrer = referrer;
    }
    if (path) {
        data.path = path;
    }
    if (url) {
        data.url = url;
    }
    if (title) {
        data.title = title;
    }
    return data;
}
function deepCopy(obj) {
    if (obj == null || obj instanceof Date || typeof obj !== 'object') {
        return obj;
    }
    if (obj instanceof Array) {
        return obj.map(i => deepCopy(i));
    }
    if (obj instanceof Object) {
        return Object.entries(obj).reduce((o, [k, v]) => Object.assign(o, { [k]: deepCopy(v) }), {});
    }
    return JSON.parse(JSON.stringify(obj));
}
function deepCopyMerge(...objects) {
    return Object.assign({}, ...objects.map(obj => deepCopy(obj)));
}
function removeEmpty(objIn, doNotCopyNested = false) {
    const obj = doNotCopyNested ? objIn : deepCopy(objIn);
    Object.entries(obj).forEach(([key, value]) => {
        if (value && typeof value === 'object' && Object.entries(value).length) {
            removeEmpty(value, true);
        }
        else if (value === null || value === undefined) {
            delete obj[key]; // eslint-disable-line no-param-reassign
        }
    });
    return obj;
}
function mergeMetaData({ userId, visitorId, sessionId, data }) {
    return removeEmpty({ ...data, userId, visitorId, sessionId });
}
function hasPiiKeyword(key, piiKeywords, allowList) {
    const lowerCaseKey = key.toLowerCase();
    return !allowList.includes(lowerCaseKey) && piiKeywords.some(pii => lowerCaseKey.includes(pii));
}
function removeUnsupportedFullStoryProperties(data) {
    const obj = deepCopy(data);
    Object.entries(obj).forEach(([key]) => {
        if (key.startsWith('_')) {
            delete obj[key]; // eslint-disable-line no-param-reassign
        }
    });
    return obj;
}
function removePiiEntries(objIn, piiKeywords = [], allowList = [], doNotCopyNested = false) {
    const obj = doNotCopyNested ? objIn : deepCopy(objIn);
    Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object' && Object.entries(value ?? {}).length) {
            removePiiEntries(value, piiKeywords, allowList, true);
        }
        else if (hasPiiKeyword(key, piiKeywords, allowList)) {
            delete obj[key];
        }
    });
    return obj;
}
/**
 * This function filters out data that is not of interest to fullstory due to its limit on attributes
 * @param {*} data The object containing all properties to track
 */
function removeUnwantedFullStoryProperties(data) {
    const obj = deepCopy(data);
    Object.entries(obj).forEach(([key]) => {
        if (constants_1.UNWANTED_FULLSTORY_TRACK_CALL_PROPERTIES.includes(key) || key.startsWith('gst')) {
            delete obj[key]; // eslint-disable-line no-param-reassign
        }
    });
    return obj;
}
