import {
  USER_PROFILE_SERVICE_COOKIE_NAME,
  USER_PROFILE_SERVICE_ORIGIN_HASH_COOKIE_NAME,
} from './constants';
import * as CookiesUtils from './cookies_utils';
import Identifier from './identifiers';

class UserProfileService {
  static getUserProfileServiceId() {
    // This does nothing if valid UPS cookie has been set already
    Identifier.authorizeIdentifier(USER_PROFILE_SERVICE_COOKIE_NAME);

    return CookiesUtils.getCookie(USER_PROFILE_SERVICE_COOKIE_NAME);
  }

  static getUserProfileServiceIdOriginHash() {
    const cookie = CookiesUtils.getCookie(USER_PROFILE_SERVICE_ORIGIN_HASH_COOKIE_NAME);

    return cookie && decodeURIComponent(cookie);
  }

  static checkResetUserProfileServiceId(userId) {
    return Identifier.checkResetIdentifier(
      userId,
      USER_PROFILE_SERVICE_ORIGIN_HASH_COOKIE_NAME,
      USER_PROFILE_SERVICE_COOKIE_NAME,
    );
  }
}

export default UserProfileService;
