import { ONETRUST_COOKIE_NAME } from '../constants';
import { getCookie } from '../cookies_utils';
import {
  mergeMetaData,
  removePiiEntries,
  removeUnsupportedFullStoryProperties,
  removeUnwantedFullStoryProperties,
} from '../helpers/helper';
import { addOnetrustCookies } from '../helpers/snowplow_helper';
import { loadFS, windowFS } from './fullstory_helper';
import WrappedProvider from './wrapped_provider';

const PII_KEYWORDS = ['name', 'phone', 'email', 'ssn'];
const ALLOW_KEYWORDS = ['experiment_name', 'variation_name'];
class FullstoryProvider extends WrappedProvider {
  load() {
    // Loading OneTrust Consent Information from Cookie
    const onetrustConsentInformation = addOnetrustCookies(getCookie(ONETRUST_COOKIE_NAME));
    // Based on the schema for consent information in this file: ../../optanon_consent_cookies.json
    // We check if user has opted in for performance cookies before loading fullstory

    if (onetrustConsentInformation && onetrustConsentInformation.performance_cookies === 'opt_in') {
      loadFS(this.config.fs_org);
    }

    // We are adding a log statement to put the value of onetrustConsentInformation as well to make sure we are parsing cookies correctly and cookie is available
    window.console.log(
      'Privacy Consent Information from Cookie Banner: ',
      onetrustConsentInformation,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  identify({ userId, visitorId, sessionId, data }) {
    const fsData = mergeMetaData({ userId, visitorId, sessionId, data });
    let filteredFsData = removePiiEntries(fsData, PII_KEYWORDS, ALLOW_KEYWORDS);
    filteredFsData = removeUnsupportedFullStoryProperties(filteredFsData);
    if (userId) {
      windowFS('identify', userId, filteredFsData);
    } else {
      windowFS('setUserVars', filteredFsData);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  track({ data, eventCategory, eventName, sessionId, userId, visitorId }) {
    const fsData = mergeMetaData({ userId, visitorId, sessionId, data });
    let filteredFsData = removePiiEntries(fsData, PII_KEYWORDS, ALLOW_KEYWORDS);
    filteredFsData = removeUnsupportedFullStoryProperties(filteredFsData);
    filteredFsData = removeUnwantedFullStoryProperties(filteredFsData);
    windowFS('event', `${eventCategory}${eventName}`, filteredFsData);
  }

  // eslint-disable-next-line class-methods-use-this
  page() {}

  // eslint-disable-next-line class-methods-use-this
  group() {}
}

FullstoryProvider.configKey = 'fullstory';
FullstoryProvider.isApprovedProvider = false;
FullstoryProvider.providerName = 'Fullstory';

export default FullstoryProvider;
