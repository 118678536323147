import { buildCamel } from './build_helper';

// For testing purposes
// Use this if specs require stubbing window.location attributes
export function getWindowLocationHref() {
  return window.location.href;
}

export function getWindowLocationSearch() {
  return window.location.search;
}

export function getWindowLocationHostname() {
  return window.location.hostname;
}

export function getWindowLocationPathname() {
  return window.location.pathname;
}

export function getWindowLocationPort() {
  return window.location.port;
}

export function getDocumentReferrer() {
  return document.referrer;
}

export function getDocumentTitle() {
  return document.title;
}

export function getClientUserAgent() {
  return window.navigator.userAgent;
}

export function isPageVisible() {
  return document.visibilityState === 'visible';
}

const VENDOR_PREFIXES = ['', 'ms', 'webkit'];

export function pageVisibleEventName() {
  const visPrefix = VENDOR_PREFIXES.find(vendorPrefix => {
    const prop = buildCamel(vendorPrefix, 'hidden'); // hidden, msHidden, webkitHidden
    return document[prop] !== undefined; // document.hidden, document.msHidden, document.webkitHidden
  });
  return `${visPrefix}visibilitychange`;
}
