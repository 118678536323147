// @team DEx

// Send errors to DataDog
//
// Decouples reporting errors from the service used, setting us
// up for success if we want to switch in the future
//
// Copied from ZP. Should update to TS when that's available.
// https://github.com/Gusto/zenpayroll/blob/development/frontend/javascripts/lib/track_error.ts

export default function trackError({ error = {}, custom = {} }) {
  if (window.DD_RUM && window.DD_RUM.addError) {
    if (Object.keys(custom).length === 0) {
      window.DD_RUM.addError(error);
    } else {
      window.DD_RUM.addError(error, custom);
    }
  }
}
