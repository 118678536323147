"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseConfig = void 0;
function parseConfigString(str) {
    const el = document.createElement('span');
    el.innerHTML = str;
    return JSON.parse(el.innerText);
}
const parseConfig = (gustoAC = window.gustoAC) => typeof gustoAC === 'string' ? parseConfigString(gustoAC) : gustoAC;
exports.parseConfig = parseConfig;
