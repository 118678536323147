/* eslint-disable no-param-reassign */

const mungeStringContent = str => str.charAt(0) + '*'.repeat(str.slice(1).length);
const mungeString = str => (!str ? str : mungeStringContent(str.trim()));
const mungeObj = obj =>
  Object.entries(obj).reduce(
    (mungedObj, [k, v]) => ({
      ...mungedObj,
      ...(v !== undefined && { [`${k}Munged`]: mungeString(v) }),
    }),
    {},
  );

const attributeObject = attrs => {
  if (!attrs) {
    return {};
  }
  return Object.values(attrs).reduce(
    (result, { name, value }) => ({ ...result, [name]: value }),
    {},
  );
};
// Include only whitelisted attributes to avoid overloading Amplitude properties
const sanitizedWhitelistedAttributes = ({ href, label, name, src, title, type, value }) => ({
  href,
  label,
  name,
  src,
  title,
  type,
  ...mungeObj({ value }),
});

// Don't include 'nodeType' as Amplitude doesn't allow it
export const targetProperties = targetElement => {
  if (!targetElement) {
    return {
      attributes: {},
      attributesList: [],
      className: '',
      classList: [],
      disabled: false,
      id: '',
      nodeName: '',
      textContentMunged: '',
    };
  }

  const { attributes, className, classList, disabled, id, nodeName, textContent } = targetElement;

  const attributeObj = attributeObject(attributes);
  return {
    attributes: sanitizedWhitelistedAttributes(attributeObj),
    attributesList: Object.keys(attributeObj),
    className,
    classList: Array.from(classList || (className ? className.split(' ') : [])), // IE should include this but "support is limited" so let's be careful
    disabled,
    id,
    nodeName, // nodeName === tagName for elements and additionally supports other node types
    textContentMunged: mungeString(textContent),
  };
};

const locationProperties = ({ hash, host, href, pathname, search }) => ({
  hash,
  host,
  href,
  pathname,
  search,
});

export const globalProperties = () => {
  const { referrer, title } = document;
  return {
    document: { referrer, title },
    window: { location: locationProperties(window.location) },
  };
};

export const clickSchemaProperties = ({ target, currentTarget }) => {
  const safeCurrentTarget = currentTarget || {};
  const safeTarget = target || {};

  return {
    actionSubject: safeCurrentTarget.nodeName || '',
    actionSubjectTarget: safeCurrentTarget.href || '',
    actionSubjectId: safeCurrentTarget.id || safeTarget.id || '',
    actionSubjectClass: safeCurrentTarget.className || '',
    actionSubjectType: safeCurrentTarget.type || '',
  };
};

export const eventProperties = ({ currentTarget, target, timeStamp, type }) => ({
  // Ensure null values are safely passed to targetProperties
  currentTarget: targetProperties(currentTarget || {}),
  target: targetProperties(target || {}),
  timeStamp,
  type,
});

const scrollState = {
  pos: 0,
};

export const scrollProperties = () => {
  const { documentElement, body } = document;
  const st = 'scrollTop';
  const sh = 'scrollHeight';
  let direction = '';
  const percent = parseInt(
    ((documentElement[st] || body[st]) /
      ((documentElement[sh] || body[sh]) - documentElement.clientHeight)) *
      100,
    10,
  );
  if (percent > scrollState.pos) {
    direction = 'DOWN';
  } else {
    direction = 'UP';
  }
  scrollState.pos = percent;
  return {
    percent,
    direction,
  };
};
