class WrappedProvider {
  constructor(config) {
    this.config = config;
  }

  load() {
    this.throwUnimplemented();
  }

  identify() {
    this.throwUnimplemented();
  }

  group() {
    this.throwUnimplemented();
  }

  page() {
    this.throwUnimplemented();
  }

  track() {
    this.throwUnimplemented();
  }

  throwUnimplemented() {
    throw new Error(`Implement in ${this.constructor.name}`);
  }
}

WrappedProvider.configKey = 'wrappedProvider';
WrappedProvider.isApprovedProvider = false;
WrappedProvider.providerName = 'Wrapped Provider';

export default WrappedProvider;
