"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalize = void 0;
exports.buildCamel = buildCamel;
const capitalize = (str) => str[0].toUpperCase() + str.slice(1);
exports.capitalize = capitalize;
// Concatenate camel case strings into 1 camel case string
function buildCamel(...args) {
    const [first, ...others] = args.filter(str => str.length);
    return [first, ...others.map(str => (0, exports.capitalize)(str))].join('');
}
