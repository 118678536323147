class WrappedProvider {
  constructor(config) {
    this.config = config;
  }

  load() {
    this.throwUnimplemented();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  identify(_data) {
    this.throwUnimplemented();
  }

  group() {
    this.throwUnimplemented();
  }

  page() {
    this.throwUnimplemented();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track(_data) {
    this.throwUnimplemented();
  }

  throwUnimplemented() {
    throw new Error(`Implement in ${this.constructor.name}`);
  }
}

WrappedProvider.configKey = 'wrappedProvider';
WrappedProvider.isApprovedProvider = false;
WrappedProvider.providerName = 'Wrapped Provider';

export default WrappedProvider;
